import React from "react";

import nat1Small from "../assets/images/about-1-small.jpg";
import nat2Small from "../assets/images/about-2-small.jpg";
import nat3Small from "../assets/images/about-3-small.jpg";

import storyImg1 from "../assets/images/nat-8.jpg";
import storyImg2 from "../assets/images/nat-9.jpg";

import videoMp4 from "../assets/images/video.mp4";
import videoWebm from "../assets/images/video.webm";

import "../assets/css/main.css";

import gal1 from "../assets/images/gallery/gallery (1).jpg";
import gal2 from "../assets/images/gallery/gallery (2).jpg";
import gal3 from "../assets/images/gallery/gallery (3).jpg";
import gal4 from "../assets/images/gallery/gallery (4).jpg";
import gal5 from "../assets/images/gallery/gallery (5).jpg";
import gal6 from "../assets/images/gallery/gallery (6).jpg";
import gal7 from "../assets/images/gallery/gallery (7).jpg";
import gal8 from "../assets/images/gallery/gallery (8).jpg";
import gal9 from "../assets/images/gallery/gallery (9).jpg";
import gal10 from "../assets/images/gallery/gallery (10).jpg";
import gal11 from "../assets/images/gallery/gallery (11).jpg";
import gal12 from "../assets/images/gallery/gallery (12).jpg";
import gal13 from "../assets/images/gallery/gallery (13).jpg";
import gal14 from "../assets/images/gallery/gallery (14).jpg";


function Home() {
  return (
    <div>
      <main>
        <section className="section-about">
          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">When moments are important</h2>
          </div>

          <div className="row">
            <div className="col-1-of-2">
              <h3 className="heading-tertiary u-margin-bottom-small">
                You want to book a professional
              </h3>
              <p className="paragraph">
                We are not just technically proficiency but genuinely committed
                to creating an experience that goes beyond the click of a
                shutter. As a proud member of Professional Photographers of
                America and Texas Professional Photographers of America, We bring
                a blend of technical expertise and artistic flair to every
                project. I am committed to continuous learning, staying abreast
                of industry trends, and exploring new techniques to elevate my
                craft.
              </p>

              <h3 className="heading-tertiary u-margin-bottom-small">
                Let's Create Together
              </h3>
              <p className="paragraph">
                Collaboration is at the heart of what I do. I want to get to
                know you, understand your vision, and collaborate to create
                images that reflect your personality and the essence of the
                moment. Whether it's the impact of a headshot, the joy of a family gathering,
                or the professionalism of a corporate event, I'm here to bring
                your story to life.
              </p>

              {/* <h3 className="heading-tertiary u-margin-bottom-small">You want the process to be easy</h3>
                        <p className="paragraph">
                            From online services to personal consultations, ... 
                        </p> */}

              {/* <a href="#" className="btn-text">
                Learn more &rarr;
              </a> */}
            </div>
            <div className="col-1-of-2">
              <div className="composition">
                <img
                  srcSet={`${nat3Small} 300w`}
                  sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                  alt="1"
                  className="composition__photo composition__photo--p1"
                  src={nat3Small}
                />

                <img
                  srcSet={`${nat2Small} 300w`}
                  sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                  alt="2"
                  className="composition__photo composition__photo--p2"
                  src={nat2Small}
                />

                <img
                  srcSet={`${nat1Small} 300w`}
                  sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                  alt="3"
                  className="composition__photo composition__photo--p3"
                  src={nat3Small}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-features">
          <div className="row">
            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-world"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  How to Book a Session?
                </h3>
                <p className="feature-box__text">
                  Just fill out our booking form and we will call to
                  confirm availability and discuss your needs to ensure they are
                  met.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-compass"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  What services do you offer?
                </h3>
                <div className="feature-box__text">
                  <p>Headshots</p>
                  <p>Family Portraits</p>
                  <p>Corporate Events</p>
                  <p>Creative Projects and Collaborations</p>
                </div>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-map"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  How do I prepare for a session?
                </h3>
                <p className="feature-box__text">
                  I'll provide you with a detailed preparation guide upon
                  booking. It includes tips on clothing choices and any specific
                  details to ensure a smooth and enjoyable session.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-heart"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  What happens in case of bad weather?
                </h3>
                <p className="feature-box__text">
                  In the event of inclement weather, we can reschedule
                  to ensure the best possible conditions. Your comfort and the
                  quality of the images are my top priorities.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-tours" id="section-tours">
          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">Most popular services</h2>
          </div>

          <div className="row">
            <div className="col-1-of-3">
              <div className="card">
                <div className="card__side card__side--front">
                  <div className="card__picture card__picture--1">&nbsp;</div>
                  <h4 className="card__heading">
                    <span className="card__heading-span card__heading-span--1">
                      Portraits
                    </span>
                  </h4>
                  <div className="card__details">
                    <ul>
                      <li>On location</li>
                      <li>Personal Consultation</li>
                      <li>Customized Session</li>
                      <li>Digital or Prints</li>
                    </ul>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__price-box">
                      <p className="card__price-only">Starting at</p>
                      <p className="card__price-value">$300</p>
                    </div>
                    <a href="#popup" className="btn btn--white">
                      Book now!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-1-of-3">
              <div className="card">
                <div className="card__side card__side--front">
                  <div className="card__picture card__picture--2">&nbsp;</div>
                  <h4 className="card__heading">
                    <span className="card__heading-span card__heading-span--2">
                      Headshots
                    </span>
                  </h4>
                  <div className="card__details">
                    <ul>
                      <li>On location</li>
                      <li>Personal Consultation</li>
                      <li>High Resolution Image</li>
                      <li>Online delivery</li>
                    </ul>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-2">
                  <div className="card__cta">
                    <div className="card__price-box">
                      <p className="card__price-only">Starting at</p>
                      <p className="card__price-value">$150</p>
                    </div>
                    <a href="#popup" className="btn btn--white">
                      Book now!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-1-of-3">
              <div className="card">
                <div className="card__side card__side--front">
                  <div className="card__picture card__picture--3">&nbsp;</div>
                  <h4 className="card__heading">
                    <span className="card__heading-span card__heading-span--3">
                      Events
                    </span>
                  </h4>
                  <div className="card__details">
                    <ul>
                      <li>Personal Consultation</li>
                      <li>High Resolution Image</li>
                      <li>Online delivery</li>
                    </ul>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-3">
                  <div className="card__cta">
                    <div className="card__price-box">
                      <p className="card__price-only">Starting at</p>
                      <p className="card__price-value">$150</p>
                    </div>
                    <a href="#popup" className="btn btn--white">
                      Book now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="u-center-text u-margin-top-huge">
            <a href="#" className="btn btn--green">
              Discover all tours
            </a>
          </div> */}
        </section>
{/* 
        <section className="section-stories">
          <div className="bg-video">
            <video className="bg-video__content" preload="none" autoPlay muted loop>
              <source src={videoMp4} type="video/mp4" />
              <source src={videoWebm} type="video/webm" />
              Your browser is not supported!
            </video>
          </div>

          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
              We make people genuinely happy
            </h2>
          </div>

          <div className="row">
            <div className="story">
              <figure className="story__shape">
                <img
                  src={storyImg1}
                  alt="Person on a tour"
                  className="story__img"
                />
                <figcaption className="story__caption">Mary Smith</figcaption>
              </figure>
              <div className="story__text">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  title 1
                </h3>
                <p>
                  Stan is not just technically proficiency but genuinely
                  committed to creating an experience that goes beyond the click
                  of a shutter. Stan is not just a photographer but a
                  storyteller who understands the significance of each moment. I
                  would recommend Matzke Photography for anyone seeking a
                  photographer who not only captures images but creates an
                  unforgettable experience. Thank you, for preserving our
                  precious moments in such a beautiful and timeless way.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="story">
              <figure className="story__shape">
                <img
                  src={storyImg2}
                  alt="Person on a tour"
                  className="story__img"
                />
                <figcaption className="story__caption">Jack Wilson</figcaption>
              </figure>
              <div className="story__text">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Title 2
                </h3>
                <p>
                  From our initial consultation to the final delivery of images,
                  Matzke Photography demonstrated a level of professionalism
                  that put us at ease throughout the entire process. They took
                  the time to understand our vision and priorities, and it truly
                  felt like a collaborative effort. The result was a collection
                  of images that not only documented our special day but also
                  told a beautiful story.
                </p>
              </div>
            </div>
          </div>

          <div className="u-center-text u-margin-top-huge">
            <a href="#" className="btn-text">
              Read all stories &rarr;
            </a>
          </div>
        </section> */}

        <section className="section-book">
          <div className="row">
            <div className="book">
              <div className="book__form">
              <iframe name="lc_contact_form" 
              title="Booking"
              frameBorder="0" 
              width="100%" 
              height="600" src="https://MatzkePhotography.17hats.com/p#/embed/wrskdnrtswxdgkszdztgrbfppsdnzkdz">
                </iframe><script type="text/javascript" src="https://MatzkePhotography.17hats.com/vendor/iframeSizer.min.js"></script>

              
{/*                 <form action="#" className="form">
                  <div className="u-margin-bottom-medium">
                    <h2 className="heading-secondary">Book a session</h2>
                  </div>

                  <div className="form__group">
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Full name"
                      id="name"
                      required
                    />
                    <label htmlFor="name" className="form__label">
                      Full name
                    </label>
                  </div>

                  <div className="form__group">
                    <input
                      type="email"
                      className="form__input"
                      placeholder="Email address"
                      id="email"
                      required
                    />
                    <label htmlFor="email" className="form__label">
                      Email address
                    </label>
                  </div>

                  <div className="form__group u-margin-bottom-medium">
                    <div className="form__radio-group">
                      <input
                        type="radio"
                        className="form__radio-input"
                        id="small"
                        name="size"
                      />
                      <label htmlFor="small" className="form__radio-label">
                        <span className="form__radio-button"></span>
                        Small tour group
                      </label>
                    </div>

                    <div className="form__radio-group">
                      <input
                        type="radio"
                        className="form__radio-input"
                        id="large"
                        name="size"
                      />
                      <label htmlFor="large" className="form__radio-label">
                        <span className="form__radio-button"></span>
                        Large tour group
                      </label>
                    </div>
                  </div>

                  <div className="form__group">
                    <button className="btn btn--green">Next step &rarr;</button>
                  </div>
                </form> */}

              </div>
            </div>
          </div>
        </section>

        <section className="gallery">
            <figure className="gallery__item gallery__item--1 "><img src={gal1} alt="Gallery image 1" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--2"><img src={gal2}  alt="Gallery image 2" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--3"><img src={gal3}  alt="Gallery image 3" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--4"><img src={gal4}  alt="Gallery image 4" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--5"><img src={gal5}  alt="Gallery image 5" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--6"><img src={gal6}  alt="Gallery image 6" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--7"><img src={gal7}  alt="Gallery image 7" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--8"><img src={gal8}  alt="Gallery image 8" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--9"><img src={gal9}  alt="Gallery image 9" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--10"><img src={gal10}  alt="Gallery image 10" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--11"><img src={gal11}  alt="Gallery image 11" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--12"><img src={gal12}  alt="Gallery image 12" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--13"><img src={gal13}  alt="Gallery image 13" className="gallery__img" /></figure>
            <figure className="gallery__item gallery__item--14"><img src={gal14}  alt="Gallery image 14" className="gallery__img" /></figure>


        </section>

      </main>
    </div>
  );
}

export default Home;
